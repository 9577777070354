import { send } from "../index";



export function getAllocateGroupRules(data, opts = {}) {
    return send({
        url: "/admin/AdminAllocateGroupRule/searchList.do",
        method: "POST",
        data,
        ...opts,
    });
}


export function updateAllocateGroupRule(data, opts = {}) {
    return send({
        url: "/admin/AdminAllocateGroupRule/update.do",
        method: "POST",
        data,
        ...opts,
    });
}


export function saveAllocateGroupRule(data, opts = {}) {
    return send({
        url: "/admin/AdminAllocateGroupRule/save.do",
        method: "POST",
        data,
        ...opts,
    });
}


export function deleteAllocateGroupRule(data, opts = {}) {
    return send({
        url: "/admin/AdminAllocateGroupRule/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disableAllocateGroupRule(data, opts = {}) {
    return send({
        url: "/admin/AdminAllocateGroupRule/disable.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function enableAllocateGroupRule(data, opts = {}) {
    return send({
        url: "/admin/AdminAllocateGroupRule/enable.do",
        method: "POST",
        data,
        ...opts,
    });
}